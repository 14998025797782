/* eslint-disable @typescript-eslint/no-explicit-any */

export const rejectedSignatureMessages = [
  "user disapproved requested methods", // WalletConnect
  "Transaktion storniert",
  "İşlem iptal edildi",
  "Transactie geannuleerd",
  "Transaction annulée",
  "거래 취소됨",
  "Транзакция отменена",
  "Transaksi dibatalkan",
  "canceled",
  "cancelled",
  "rejected",
  "reject",
  "denied",
  "declined",
  "cancelada",
  "aborted",
  "交易已取消",
  "Anda membatalkan",
];

const errorSnippets = {
  mainnetFull: ["0xec6"],
  checkValidatorCommision: ["0xec6"],
  accountLockup: ["0xb3aa"],
  stakeAccountNotUpdated: ["0x1787"],
  reserveHasNoData: ["0x1771"],
  invalidAccountData: ["invalid account data for instruction"],
  noPriorRecord: ["no record of a prior credit"],
  insufficientFunds: ["insufficient funds for instruction", "0x1798"],
  ticketNotReady: ["0x1104"],
  rejectedSignature: rejectedSignatureMessages,
  liquidationImpossible: ["amount too low for liquidation"],
  activatingAccount: ["account is activating"],
  failedSignatureError: ["Failed to sign transaction"],
  slowSigningError: [
    "Blockhash not found",
    "block height exceeded",
    "Request expired",
  ],
  noEVMSupportError: [
    "Non-EVM contract calls are not enabled on your workspace",
  ],
  bigTransaction: ["encoding overruns Uint8Array"], // Probably, not sure exactly the reason, too low occurence to investigate further
  requestedResourceNotAvailable: ["Requested resource not available", "-32002"], // Phantom errors
  notSupportedVersionedTx: [
    "Sending versioned transactions isn't supported by this wallet",
    "Versioned transactions are not supported",
  ],
  versionedTxError: ["versioned transaction not supported"],
  internalJSONRPCError: ["Internal JSON-RPC error"], // Possibly mismatch between wallet version (older) and our wallet-adapter lib version
  reconnectWallet: [
    "The wallet session dropped unexpectedly",
    "Disconnect your DApp first and then reconnect", // WalletConnect
    "Failed to connect to the wallet",
    "请先断开 DApp，再重新连接",
  ],
  notConnected: ["not connected"],
  signatureVerificationFailed: ["Signature verification failed"],
  txProcessed: ["This transaction has already been processed"],
  wrongWallet: ["Provided owner is not allowed"],
  pluginClosed: ["Plugin Closed"],
  unableToConfirmTx: [
    "unable to confirm transaction",
    "Transaction was not confirmed",
  ],
  wrongOwner: ["This account may not be used"],
  solanaSnap: ["solana-snap"],
  maxNumberOfReq: ["maximum number of requests"],
  noFeeFunds: ["SOL not enough to cover gas fee"],
  lockedWallet: ["Password error", "wallet should be unlocked"],
  tooManyDepositRecords: [
    "You have too many deposit records. Claim or close some of them and try again",
  ],
  tryAgain: ["try again"],
  slippageExeeded: ["6001"],
  blingSign: ["BlindSign"],
  wrongChain: ["Scope/chain mismatch"],
};

export const getErrorMessage = (
  error: any,
  t: (tag: string) => string
): string | undefined => {
  const errorSnippet = Object.entries(errorSnippets).find(([, snippets]) =>
    snippets.some((snippet) =>
      error.toString().toLowerCase().includes(snippet.toLowerCase())
    )
  );

  return errorSnippet
    ? t(`appPage.errors.${errorSnippet[0]}`)
    : t("appPage.something-went-wrong");
};
